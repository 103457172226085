const entities = {
  orderObjects: [
    'Orderadmin\\Products\\Entity\\AbstractOrder',
    'Orderadmin\\Products\\Entity\\Order',
    'Orderadmin\\Products\\Entity\\Order\\RetailOrder',
    'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder',
    'Orderadmin\\Products\\Entity\\Order\\ReturnOrder',
    'Orderadmin\\Products\\Entity\\Order\\Disposal',
  ],
  placeObjects: [
    'Orderadmin\\Storage\\Entity\\Place',
    'Orderadmin\\Storage\\Entity\\Place\\Row',
    'Orderadmin\\Storage\\Entity\\Place\\Section',
    'Orderadmin\\Storage\\Entity\\Place\\StaticLocation',
    'Orderadmin\\Storage\\Entity\\Place\\Dynamic',
    'Orderadmin\\Storage\\Entity\\Place\\Employee',
    'Orderadmin\\Storage\\Entity\\Place\\Sorting',
    'Orderadmin\\Storage\\Entity\\Place\\Distribution',
    'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected',
    'Orderadmin\\Storage\\Entity\\Place\\Assembly',
    'Orderadmin\\Storage\\Entity\\Place\\Universal',
    'Orderadmin\\Storage\\Entity\\Place\\Pallet',
    'Orderadmin\\Storage\\Entity\\Place\\Defected',
    'Orderadmin\\Storage\\Entity\\Place\\Room',
  ],
  Orderadmin_Storage_Entity_AbstractPlace: 'Orderadmin\\Storage\\Entity\\AbstractPlace',
  Orderadmin_Storage_Entity_Place: 'Orderadmin\\Storage\\Entity\\Place',
  Orderadmin_Storage_Entity_Place_Row: 'Orderadmin\\Storage\\Entity\\Place\\Row',
  Orderadmin_Storage_Entity_Place_Section: 'Orderadmin\\Storage\\Entity\\Place\\Section',
  Orderadmin_Storage_Entity_Place_StaticLocation: 'Orderadmin\\Storage\\Entity\\Place\\StaticLocation',
  Orderadmin_Storage_Entity_Place_Dynamic: 'Orderadmin\\Storage\\Entity\\Place\\Dynamic',
  Orderadmin_Storage_Entity_Place_Employee: 'Orderadmin\\Storage\\Entity\\Place\\Employee',
  Orderadmin_Storage_Entity_Place_Sorting: 'Orderadmin\\Storage\\Entity\\Place\\Sorting',
  Orderadmin_Storage_Entity_Place_Distribution: 'Orderadmin\\Storage\\Entity\\Place\\Distribution',
  Orderadmin_Storage_Entity_Place_DistributionRejected: 'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected',
  Orderadmin_Storage_Entity_Place_Assembly: 'Orderadmin\\Storage\\Entity\\Place\\Assembly',
  Orderadmin_Storage_Entity_Place_Universal: 'Orderadmin\\Storage\\Entity\\Place\\Universal',
  Orderadmin_Storage_Entity_Place_Pallet: 'Orderadmin\\Storage\\Entity\\Place\\Pallet',
  Orderadmin_Storage_Entity_Place_Defected: 'Orderadmin\\Storage\\Entity\\Place\\Defected',
  Orderadmin_Storage_Entity_Place_Room: 'Orderadmin\\Storage\\Entity\\Place\\Room',
  Orderadmin_DeliveryServices_Entity_DeliveryRequest: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
  Orderadmin_DeliveryServices_Entity_DeliveryRequest_Place: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest\\Place',
  Orderadmin_DeliveryServices_Entity_DeliveryRequest_Item: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest\\Item',
  Orderadmin_Products_Entity_AbstractOrder: 'Orderadmin\\Products\\Entity\\AbstractOrder',
  Orderadmin_Products_Entity_Order: 'Orderadmin\\Products\\Entity\\AbstractOrder',
  Orderadmin_Products_Entity_Order_Retail: 'Orderadmin\\Products\\Entity\\Order',
  Orderadmin_Products_Entity_Shop: 'Orderadmin\\Products\\Entity\\Shop',
  Orderadmin_Storage_Entity_Item: 'Orderadmin\\Storage\\Entity\\Item',
  Orderadmin_Storage_Entity_Picking_Queue: 'Orderadmin\\Storage\\Entity\\Tasks\\Queue',
  Orderadmin_Products_Entity_Product_Offer: 'Orderadmin\\Products\\Entity\\Product\\Offer',
  Orderadmin_Products_Entity_Product_ExpectedOffer: 'Orderadmin\\Products\\Entity\\Product\\ExpectedOffer',
  Orderadmin_Storage_Entity_Picking_Task: 'Orderadmin\\Storage\\Entity\\Tasks\\Task',
  Orderadmin_Storage_Entity_Picking_Assistent: 'Orderadmin\\Storage\\Entity\\Tasks\\Assistent',
  Orderadmin_Storage_Entity_Assemblage_Queue: 'Orderadmin\\Storage\\Entity\\Assemblage\\Queue',
  Orderadmin_Storage_Entity_Assemblage_OrderProductTask: 'Orderadmin\\Storage\\Entity\\Assemblage\\OrderProductTask',
  Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask: 'Orderadmin\\Storage\\Entity\\Assemblage\\OrderProductBundleTask',
  Orderadmin_Storage_Entity_Assemblage_Task: 'Orderadmin\\Storage\\Entity\\Assemblage\\Task',
  Orderadmin_Accounts_Entity_Account: 'Orderadmin\\Accounts\\Entity\\Account',
  Orderadmin_Accounts_Entity_BillingAdapter: 'Orderadmin\\Accounts\\Entity\\BillingAdapter',
  Orderadmin_Accounts_Entity_ReportType: 'Orderadmin\\Accounts\\Entity\\ReportType',
  Orderadmin_Accounts_Entity_Report: 'Orderadmin\\Accounts\\Entity\\Report',
  Orderadmin_Accounts_Entity_Invoice: 'Orderadmin\\Accounts\\Entity\\Invoice',
  Orderadmin_Accounts_Entity_Payment: 'Orderadmin\\Accounts\\Entity\\Payment',
  Orderadmin_Accounts_Entity_LegalEntity: 'Orderadmin\\Accounts\\Entity\\LegalEntity',
  Orderadmin_Accounts_Entity_Account_Rate: 'Orderadmin\\Accounts\\Entity\\Account\\Rate',
  Message: 'message',
  Counter: 'counter',
  Collection: 'collection',
  Barcode: 'barcode',
  Batch: 'batch',
  Orderadmin_Storage_Entity_Movement_Acceptance: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
  Orderadmin_Storage_Entity_Movement_Consumption: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
  Orderadmin_Storage_Entity_Warehouse: 'Orderadmin\\Storage\\Entity\\Warehouse',
  Orderadmin_Storage_Entity_LocationLog: 'Orderadmin\\Storage\\Entity\\LocationLog',
  Orderadmin_DeliveryServices_Entity_Sender: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
  Orderadmin_Users_Entity_User: 'Orderadmin\\Users\\Entity\\User',
  Orderadmin_Users_Entity_Domain: 'Orderadmin\\Users\\Entity\\Domain',
  Orderadmin_Clients_Entity_Adresses: 'Orderadmin\\Clients\\Entity\\Adresses',
  Orderadmin_Categories_Entity_Category: 'Orderadmin\\Categories\\Entity\\Category',
  Orderadmin_Notifications_Entity_Channel: 'Orderadmin\\Notifications\\Entity\\Channel',
  Orderadmin_Notifications_Entity_Interation: 'Orderadmin\\Notifications\\Entity\\Interation',
  Orderadmin_Notifications_Entity_Task: 'Orderadmin\\Notifications\\Entity\\Task',
  Orderadmin_Notifications_Entity_Template: 'Orderadmin\\Notifications\\Entity\\Template',
  Orderadmin_Clients_Entity_Profile: 'Orderadmin\\Clients\\Entity\\Profile',
  Orderadmin_Clients_Entity_Phone: 'Orderadmin\\Clients\\Entity\\Phone',
  Orderadmin_Locations_Entity_Country: 'Orderadmin\\Locations\\Entity\\Country',
  Orderadmin_Locations_Entity_Locality: 'Orderadmin\\Locations\\Entity\\Locality',
  Orderadmin_Settings_Entity_Currency: 'Orderadmin\\Settings\\Entity\\Currency',
  Orderadmin_Settings_Entity_Length: 'Orderadmin\\Settings\\Entity\\Length',
  Orderadmin_Settings_Entity_Weight: 'Orderadmin\\Settings\\Entity\\Weight',
  Orderadmin_Settings_Entity_Domain: 'Orderadmin\\Settings\\Entity\\Domain',
  Orderadmin_Settings_Entity_Eav: 'Orderadmin\\Settings\\Entity\\Eav',
  Orderadmin_DeliveryServices_Entity: 'Orderadmin\\DeliveryServices\\Entity',
  Orderadmin_DeliveryServices_Entity_Rate: 'Orderadmin\\DeliveryServices\\Entity\\Rate',
  Orderadmin_Help_Entity_Article: 'Orderadmin\\Help\\Entity\\Article',
  Orderadmin_Help_Entity_Section: 'Orderadmin\\Help\\Entity\\Section',
  Orderadmin_Integrations_Entity_Category: 'Orderadmin\\Integrations\\Entity\\Category',
  Orderadmin_Integrations_Entity_Import: 'Orderadmin\\Integrations\\Entity\\Import',
  Orderadmin_DeliveryServices_Entity_Integration: 'Orderadmin\\DeliveryServices\\Entity\\Integration',
  Orderadmin_DeliveryServices_Entity_Postcode: 'Orderadmin\\DeliveryServices\\Entity\\Postcode',
  Orderadmin_DeliveryServices_Entity_ServicePoint: 'Orderadmin\\DeliveryServices\\Entity\\ServicePoint',
  Orderadmin_Users_Entity_Role: 'Orderadmin\\Users\\Entity\\Role',
  Orderadmin_Storage_Entity_Movement_Inventory: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
  Orderadmin_Storage_Entity_Supply: 'Orderadmin\\Storage\\Entity\\Supply',
  Orderadmin_Products_Entity_Service: 'Orderadmin\\Products\\Entity\\Service',
  Orderadmin_Storage_Entity_Service_AcceptanceService: 'Orderadmin\\Storage\\Entity\\Service\\AcceptanceService',
  Orderadmin_Storage_Entity_Service_WarehouseService: 'Orderadmin\\Storage\\Entity\\Service\\WarehouseService',
  Orderadmin_DeliveryServices_Entity_Service_DeliveryRequestService: 'Orderadmin\\DeliveryServices\\Entity\\Service\\DeliveryRequestService',
  Orderadmin_Documents_Entity_Template: 'Orderadmin\\Documents\\Entity\\Template',
  Orderadmin_Integrations_Amazon_Entity_FBA_ShippingPlan: 'Orderadmin\\Integrations\\Amazon\\Entity\\FBA\\ShippingPlan',
  Orderadmin_Integrations_Amazon_Entity_FBA_ShippingPlan_Item: 'Orderadmin\\Integrations\\Amazon\\Entity\\FBA\\ShippingPlan\\Item',
  Orderadmin_DeliveryServices_Entity_Movements_Acceptance: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
};

Object.freeze(entities);

export default entities;
